//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-708:_6274,_8054,_3840,_1534,_6336,_7308,_4322,_7642;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-708')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-708', "_6274,_8054,_3840,_1534,_6336,_7308,_4322,_7642");
        }
      }catch (ex) {
        console.error(ex);
      }