import turn14StoresDefaults from '../bigcommerce-turn14-stores/config.js';

export default {
  includes: ['bigcommerce-turn14-stores'],
  ...turn14StoresDefaults,
  Widgets: [
    ...turn14StoresDefaults.Widgets.filter((w) => !['SingleVehicleGarage'].includes(w.name)),
    {
      name: 'SingleVehicleGarage',
      location: {
        replace: 'header #select-vehicle-desktop',
        class: 'cm_single-vehicle-garage button button--secondary',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
  ],
};
