//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-708:_2126,_6596,_3264,_8360,_5084,_2696,_500,_8424;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-708')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-708', "_2126,_6596,_3264,_8360,_5084,_2696,_500,_8424");
        }
      }catch (ex) {
        console.error(ex);
      }